import Home from './components/Home'
import About from './components/About'
import Pido101 from './components/Pido101'
import Pidonomics from './components/Pidonomics'
import Faq from './components/Faq'
import Footer from './components/Footer'
import Header from './components/Header'

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
      <About />
      <Pido101 />
      <Pidonomics />
      <Faq />
      <Footer />
    </div>
  );
}

export default App;
